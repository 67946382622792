<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Pertanyaan Survey</strong>
        </h5>
        <a-divider></a-divider>
        <a-form
          :form="form"
          @submit="handleSubmit"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div class="d-flex">
            <a-form-item>
              <a-select
                style="
                  width: 180px;
                  z-index: 99;
                  border: 1px solid black;
                  border-radius: 4px;
                  margin-right: 10px;
                "
                v-decorator="[
                  'program',
                  {
                    rules: [
                      { required: true, message: 'Program harap dipilih!' },
                    ],
                  },
                ]"
                @change="handleChangeProgram"
                placeholder="Pilih Program"
              >
                <a-select-option
                  v-for="(i, index) in programTypeItems"
                  :key="index"
                  :value="i.id"
                >
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-checkbox v-decorator="['isFirst']"> Pertama </a-checkbox>
              <!-- <a-select
                style="
                  width: 180px;
                  z-index: 99;
                  border: 1px solid black;
                  border-radius: 4px;
                "
                v-decorator="[
                  'orderTo',
                  {
                    rules: [
                      { required: true, message: 'OrderTo harap dipilih!' },
                    ],
                  },
                ]"
                placeholder="Pilih Order To"
              >
                <a-select-option v-for="i in 5" :key="i" :value="i + count">
                  {{ i + count }}
                </a-select-option>
              </a-select> -->
            </a-form-item>
          </div>

          <a-form-item :wrapper-col="{ xs: { span: 24 }, sm: { span: 12 } }">
            <a-textarea
              :disabled="submitting"
              placeholder="Masukkan Pertanyaan"
              :autoSize="{ minRows: 2, maxRows: 6 }"
              v-decorator="[
                'question',
                {
                  rules: [
                    { required: true, message: 'Pertanyaan harus diisi!' },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-button
            type="primary"
            class="px-5"
            :disabled="submitting"
            html-type="submit"
          >
            Simpan Data
          </a-button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
// import router from '@/router'
// import { create } from '@/services/axios/api/obat'
import { getProgramTypeAll } from '@/services/axios/api/program'
import {
  getSurveyByProgram,
  createQuestionSurvey,
} from '@/services/axios/api/survey'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
}
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      programTypeItems: [],
      count: 0,
      submitting: false,
      statusList: [
        { id: 1, name: 'Aktif' },
        { id: 0, name: 'Tidak Aktif' },
      ],
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    async getCityList() {
      const res = await getProgramTypeAll()
      this.programTypeItems = res.data
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await createQuestionSurvey({
              programTypeId: values.program,
              question: values.question,
              isFirst: values.isFirst,
            })
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              this.$router.push('/survey')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    async handleChangeProgram(value) {
      const resCount = await getSurveyByProgram(value)
      this.count = resCount.data.length
    },
  },
}
</script>

<style lang="scss" module>
:global(.ant-checkbox-inner) {
  border-color: black !important;
  &:hover {
    border-color: #4b7cf3 !important;
  }
}
</style>
